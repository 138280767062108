import React, {useEffect, useState, useMemo } from "react";
import {useLocation } from 'react-router-dom';

import Modal from "react-modal";
import "./Modals.scss";
import PopUpPic from '../../assets/images/surveys/PopUpPic4.png'; 
import { surveyAdmission } from '../../config/urls';
Modal.setAppElement('#root');


const Modals = () => {

  const [initialModalOpen, setInitialModalOpen] = useState(false); // Control the first modal's state
  const [secondModalOpen, setSecondModalOpen] = useState(false); // Control the second modal's state
  const [firstModalClosedByOK, setFirstModalClosedByOK] = useState(false); // Track if the first modal was closed with the OK button
  const [timerExpired, setTimerExpired] = useState(false); // Whether the 20-second timer has expired
  // const [firstModalPage, setFirstModalPage] = useState(null); // Track the page where the first modal was opened
  const location = useLocation();

  // const pagesWithoutModals = [
  //     "/global-higher-education-admissions-survey-2024", 
  //     "/global-higher-education-candidates-survey-2024",
  //     "/terms-and-conditions-survey-admissions",
  //     "/terms-and-conditions-survey-candidates"
  //   ];

    const pagesWithoutModals = useMemo(() => {
      return [
        "/global-higher-education-admissions-survey-2024", 
        "/global-higher-education-candidates-survey-2024",
        "/terms-and-conditions-survey-admissions",
        "/terms-and-conditions-survey-candidates"
      ];
    }, []);

  // Fetch the firstModalPage and secondModalShownOnce from sessionStorage (if exists)
  useEffect(() => {
    // const savedFirstModalPage = sessionStorage.getItem('firstModalPage');
    // if (savedFirstModalPage) {
    //   setFirstModalPage(savedFirstModalPage);
    // }

    const secondModalShownOnce = sessionStorage.getItem('secondModalShownOnce');
    if (secondModalShownOnce === 'true') {
      setSecondModalOpen(false); // Ensure the second modal doesn't show if it has already been shown
    }
  }, []);

  // Show the initial modal only once per session
  useEffect(() => {
    const hasModalsBeenClosed = sessionStorage.getItem('modalsClosed');
    const hasInitialModalShown = sessionStorage.getItem('initialModalShown');

    // Show the initial modal only once per session, unless modals are permanently closed
    if (!hasModalsBeenClosed && !hasInitialModalShown && !pagesWithoutModals.includes(location.pathname)  ) {
      setInitialModalOpen(true);
      // setFirstModalPage(location.pathname); // Store the page where the first modal was opened
      sessionStorage.setItem('firstModalPage', location.pathname); // Save to sessionStorage
      sessionStorage.setItem('initialModalShown', 'true'); // Store that the first modal has been shown
    }
  }, [location.pathname, pagesWithoutModals]);

  // Start 20-second timer after the first modal is closed (if not closed by clicking OK)
  useEffect(() => {
    if (!initialModalOpen && sessionStorage.getItem('firstModalPage') && !firstModalClosedByOK) {
      const timer = setTimeout(() => {
        setTimerExpired(true);
      }, 20000); // 20 seconds

      return () => clearTimeout(timer);
    }
  }, [initialModalOpen, firstModalClosedByOK]);

  // If the timer expires and the user navigates to a different page, show the second modal (only once)
  useEffect(() => {
    const savedFirstModalPage = sessionStorage.getItem('firstModalPage');
    const secondModalShownOnce = sessionStorage.getItem('secondModalShownOnce');
    
    if (timerExpired && location.pathname !== savedFirstModalPage && savedFirstModalPage && secondModalShownOnce !== 'true' && !pagesWithoutModals.includes(location.pathname)) {
      setSecondModalOpen(true);
      sessionStorage.setItem('secondModalShownOnce', 'true'); // Mark that the second modal has been shown once
    }
  }, [location.pathname, timerExpired, pagesWithoutModals]);

  // Handle closing the first modal by clicking the "OK" button
  const closeInitialModalByOK = () => {
    setFirstModalClosedByOK(true); // Track that the user clicked "OK"
    setInitialModalOpen(false);
    sessionStorage.setItem('modalsClosed', 'true'); // Prevent modals from showing again
  };

  // Handle closing the first modal using the close button (not OK)
  const closeInitialModal = () => {
    setInitialModalOpen(false);
  };

  // Handle closing the second modal
  const closeSecondModal = () => {
    setSecondModalOpen(false);
    sessionStorage.setItem('modalsClosed', 'true'); // Prevent modals from showing again
  };

    return (

        <div className="SurveyModals">
            {/* FIRST Modal */}
            <Modal className="Modal FirstModal" overlayClassName="Overlay" isOpen={initialModalOpen} onRequestClose={closeInitialModal} shouldCloseOnOverlayClick={true}>
                <div className="ModalWrapper">
                    <div className="closeModal" onClick={closeInitialModal}> </div>
                    
                    <div className="ModalContent">
                        <img src={PopUpPic}  alt="" />

                        <p><em>*Powered by Advent Group and Times Higher Education</em></p>

                        <div className="Button">
                            <a href={surveyAdmission} target="_blank" rel="noreferrer" onClick={closeInitialModalByOK}>
                                  START THE SURVEY
                            </a>              
                        </div>     
                    </div>
                </div>
            </Modal>

            {/* SECOND Modal */}
            <Modal className="Modal SecondModal" overlayClassName="Overlay" isOpen={secondModalOpen} onRequestClose={closeSecondModal} shouldCloseOnOverlayClick={true}>
                <div className="ModalWrapper">
                    <div  className="closeModal" onClick={closeSecondModal}> </div>
                    
                    <div className="ModalContent">
                        <h4>
                          Complete the survey <br /> <em>Higher Education Landscape, 2024*</em> 
                        </h4>

                        <p><em>*Powered by Advent Group and Times Higher Education</em></p>

                        <div className="Button">
                            <a href={surveyAdmission} target="_blank" rel="noreferrer" >
                                START THE SURVEY
                            </a>              
                        </div>     
                    </div>
                </div>
            </Modal>
        </div>

    );
};

export default Modals;