import './Fallback.scss';

function FallBack(props) {
    return (
        <div className="loading">
            <div className="loading-text">
                <span className="loading-text-words">A</span>
                <span className="loading-text-words">D</span>
                <span className="loading-text-words">V</span>
                <span className="loading-text-words">E</span>
                <span className="loading-text-words">N</span>
                <span className="loading-text-words">T</span>
                <span className="loading-text-words"> </span>
                <span className="loading-text-words">G</span>
                <span className="loading-text-words">R</span>
                <span className="loading-text-words">O</span>
                <span className="loading-text-words">U</span>
                <span className="loading-text-words">P</span>
            </div>
        </div>
    )
}

export default FallBack;