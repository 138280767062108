// Export the configuration for Bamboo
export const bambooURL = 'https://adventgroup.bamboohr.com/jobs/embed2.php';

// Export the API endpoint configuration
export const api = {
    'host': 'https://api.adventgroup.net',
    'headers': {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
}

export const connectURL = 'https://adventconnect.net';

export const surveyAdmission = 'https://www.surveymonkey.com/r/6YRRR9K';
export const surveyCandidates = 'https://www.surveymonkey.com/r/TCSV2M2';