import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#29bfc0',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
        white: {
            light: '#fff',
            main: '#fff',
            dark: '#f6f6f6',
            contrastText: '#29bfc0',
        },
        success: {
            light: '#ef5350',
            main: '#00e676',
            dark: '#00e676',
            // contrastText: '#29bfc0',
        },
        error: {
            light: '#69f0ae',
            main: '#f44336',
            dark: '#e53935',
        },

    },
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: '8px 25px',
                    borderRadius: '20px',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                        // borderBottom: '2px solid red'
                    },
                },
                text: {
                    // color: 'red',
                    '&:hover': {
                        background: 'none',
                        color: '#29bfc0',
                        '.MuiButton-endIcon': {
                            position: 'relative',
                            left: 5,
                        }
                    },
                    '.MuiButton-endIcon': {
                        position: 'relative',
                        left: 0,
                        top: 0,
                        transition: 'all .2s',
                    },
                }
            },
            variants: [
                {
                    props: { variant: 'textLower' },
                    style: {
                        textTransform: 'none',
                        paddingLeft: 0,
                        paddingRight: 0,
                        justifyContent: 'flex-start',
                        minWidth: 'auto',
                        '&:hover': {
                            color: "#29bfc0",
                            background: 'none'
                        },
                    },
                },
                {
                    props: { variant: 'dashed', color: 'secondary' },
                    style: {
                        // border: `4px dashed red`,

                    },
                },
            ],
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    // color: 'green',
                    '&.Mui-error:not(.InGroup)': {
                        color: '#d32f2f',
                        'a': {
                            color: '#d32f2f',
                        }
                    }
                },
            },

        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.InGroup': {
                        fontWeight: 500,
                        marginBottom: 15,
                        color: '#222',
                        '&.Mui-error': {
                            color: '#d32f2f',
                        }
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderWidth: "1px !important"
                        }
                    }
                    // border: '3px solid red'
                },
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        '&.Mui-focused': {
                            borderColor: 'green'
                        },
                        // border: `4px dashed red`,
                    },
                },
            ],
        },
    },
});

export default theme;